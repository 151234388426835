import { useState, useRef, useEffect } from 'react';
import { decks_config } from './Decks';
import SVG from 'react-inlinesvg';
import './Vessel.css';
import Deck from './Deck';

export default function Vessel_Hollandia(props) {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const svgRef = useRef(null);
    const [svgCoordiates, setSvgCoordinates] = useState({ x: 0, y: 0 });
    const [viewBox, setViewBox] = useState({ x: 0, y: 0, w: width, h: height });
    const [scale, setScale] = useState(1);
    const [isPanning, setIsPanning] = useState(false);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });

    const centerX = window.innerWidth / 2.0;
    const centerY = window.innerHeight / 2.0;

    function centerView() {
        const { width, height } = svgRef.current.getBBox();
        const w = width / 2.0;
        const h = height / 2.0;

        setSvgCoordinates({ x: centerX - w, y: centerY - h });
    };


    function handleLoaded() {
        centerView();

    }

    useEffect(() => {
        if (svgRef.current) {
            const initialScale = 2;
            const { x, y } = svgCoordiates;
            const scaledX = initialScale * (x - centerX) + centerX;
            const scaledY = initialScale * (y - centerY) + centerY;
            svgRef.current.setAttribute('transform', `matrix(${initialScale}, 0, 0, ${initialScale}, ${scaledX}, ${scaledY})`);
        }

    }, [svgCoordiates])


    function onMouseWheelHandler(e) {
        let w = viewBox.w;
        let h = viewBox.h;
        let mx = e.nativeEvent.offsetX;
        let my = e.nativeEvent.offsetY;
        let dw = w * Math.sign(e.deltaY * -1) * 0.05;
        let dh = h * Math.sign(e.deltaY * -1) * 0.05;
        let dx = dw * mx / width;
        let dy = dh * my / height;
        let newViewBox = { x: viewBox.x + dx, y: viewBox.y + dy, w: viewBox.w - dw, h: viewBox.h - dh }
        setViewBox(newViewBox);
        setScale(width / newViewBox.w);
    }

    function onMouseDownHandler(e) {
        setIsPanning(true);
        setStartPoint({ x: e.clientX, y: e.clientY });
    }

    function onMouseMoveHandler(e) {
        if (isPanning) {
            const endPoint = { x: e.clientX, y: e.clientY };
            const dx = (startPoint.x - endPoint.x) / scale;
            const dy = (startPoint.y - endPoint.y) / scale;
            setViewBox((prev) => ({
                x: prev.x + dx,
                y: prev.y + dy,
                w: prev.w,
                h: prev.h,
            }));
            //If start point is not updated every mouse move then the panning becomes weird, but in vanilla javascript only the start point on mouse down is enough?
            setStartPoint(endPoint);
        }
    }

    function onMouseUpHandler(e) {
        if (isPanning) {
            setIsPanning(false);
        }
    }

    function onMouseLeaveHandler(e) {
        setIsPanning(false);
    }

    const lane_1 = {
        width: 38.67,
        height: 7.48
    }

    return (
        <div className="vessel-root">
            <>
                {/*?xml version="1.0" encoding="utf-8"?*/}
                {/* Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
                <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
                    onWheel={onMouseWheelHandler}
                    onMouseDown={onMouseDownHandler}
                    onMouseMove={onMouseMoveHandler}
                    onMouseUp={onMouseUpHandler}
                    onMouseLeave={onMouseLeaveHandler}

                >

                    <g ref={svgRef}>
                        {decks_config.map((value, i) => {
                            return <g key={value.id}><Deck yCoord={i*100} source={value.source} handleLoaded={handleLoaded} config={value} svgRef={svgRef}/></g>
                        })}
                    </g>


                </svg>

            </>


        </div>
    )
}