import ProTable from '@ant-design/pro-table';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
const { Text } = Typography;

export default function Terminal(props) {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        props.fetchData();
    }, [])

    const columns = [
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: 150,
            render: (text, record) => 
                <>
                    <Text>{text.charAt(0).toUpperCase() + text.slice(1)}</Text>
                </>
            
        },
        {
            title: 'Trips',
            dataIndex: 'trips',
            key: 'trips',
        },
        {
            title: "24 hours",
            dataIndex: "last_24_hours",
            key: "last_24_hours",
        },
        {
            title: "Today",
            dataIndex: "today",
            key: "today"
        },
        {
            title: "Yesterday",
            dataIndex: "yesterday",
            key: "yesterday"
        },
        {
            title: "Last 7 days",
            dataIndex: "last_7_days",
            key: "last_7_days"
        }
    ]

    return (
        <ProTable
            columns={columns}
            dataSource={props.data}
            loading={props.loading}
            search={false}
        />
    )
}