import React, { useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import moment from 'moment';

import { MapContainer, TileLayer, Popup, Circle, ImageOverlay, Polyline, Polygon, LayersControl } from 'react-leaflet'
import { GlobalOutlined } from "@ant-design/icons";
import { Button, Typography, Row, Col, Checkbox, Select } from 'antd';
import { hexcolor_array } from "../Helpers";
const { Text } = Typography;
const { BaseLayer } = LayersControl;


// Map controls
const position = [51.9054, 4.3612]
const mapBounds = [[51.901172, 4.358327], [51.90955, 4.368601]]


export default function SingleTripMergeMap(props) {

    const [tripEvents, setTripEvents] = useState([]);
    const [polylineLocations, setPolylineLocations] = useState([]);
    const [parkingSlots, setParkingSlots] = useState([]);
    const [tripType, setTripType] = useState(0);
    const [trip, setTrip] = useState({});
    const [colors, setColors] = useState([]);
    const [tripColors, setTripColors] = useState({});

    const [tripFilter, setTripFilter] = useState([]);
    const [tripLookup, setTripLookup] = useState([]);

    // Map switch
    const [showMap, toggleMap] = useState(true);

    const shuffleArray = (array_shuffle) => {
        let array = array_shuffle;
        let currentIndex = array.length;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    };

    useEffect(() => {
        // Shuffle the hexcolorArray when the component mounts
        const shuffledColors = shuffleArray([...hexcolor_array]);
        setColors(shuffledColors);
    }, []);

    useEffect(() => {
        let newTrip = [];
        let newLocations = [];
        let trip_ids = [];
        let trip_index = 0;
        let first_iter = true;
        let tripColorMap = {};
        props.tripEvents.map(row => {
            if (row.payload?.position?.latitude && row.payload?.position?.longitude) {
                if (!trip_ids.includes(row.trip_id)) {
                    trip_ids.push(row.trip_id);
                    newLocations.push({trip_id: row.trip_id, path: []});
                    if (first_iter){
                        first_iter = false;
                    } else{
                        trip_index += 1;
                    }
                    tripColorMap[row.trip_id] = colors[trip_index];

                }
                newTrip.push(row)
                newLocations[trip_index].path.push([row.payload.position.latitude, row.payload.position.longitude])
            }
        })
        console.log(tripColorMap);
        setTripColors(tripColorMap);
        setTripEvents(newTrip);
        setPolylineLocations(newLocations);
        console.log(tripColorMap["1722238915557060"]);
    }, [props.tripEvents])

    useEffect(() => {
        let newParkingSectors = []

        props.parkingSlots.map(row => {

            newParkingSectors.push(row)

        })

        setParkingSlots(newParkingSectors)
    }, [props.parkingSlots])

    useEffect(() => {
        if (props.trip.length == 0) return;
        setTripType(props.trip[0].trip_type);
        setTrip(props.trip[0]);
    }, [props.trip])

    useEffect(() => {
        if (props.tripIds == null) return;
        let tempTripLookup = [];
        props.tripIds.forEach(tripId => {
            tempTripLookup.push({ value: tripId });
        });
        setTripLookup(tempTripLookup);
    }, [props.tripIds])

    function handleTripFilter(){

        let newTrip = [];
        let newLocations = [];
        let trip_ids = [];
        let trip_index = 0;
        let first_iter = true;
        props.tripEvents.map(row => {
            if (row.payload?.position?.latitude && row.payload?.position?.longitude && (tripFilter.length == 0 || tripFilter.includes(row.trip_id))) {
                if (!trip_ids.includes(row.trip_id)) {
                    trip_ids.push(row.trip_id);

                    newLocations.push({trip_id: row.trip_id, path: []});
                    if (first_iter){
                        first_iter = false;
                    } else{
                        trip_index += 1;
                    }

                }
                newTrip.push(row)
                newLocations[trip_index].path.push([row.payload.position.latitude, row.payload.position.longitude])
            }
        })
        setTripEvents(newTrip)
        setPolylineLocations(newLocations)
    }

    useEffect(() => {

        handleTripFilter();
    }, [tripFilter])

    const toggleAllEvents =
        <Checkbox
            onChange={e => props.setShowAllEvents(e.target.checked)}

        > <Text strong>Show all events</Text>
        </Checkbox>

    const selectTripsBox =
        <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            value={tripFilter}
            options={tripLookup}
            onChange={val => setTripFilter(val)}
            maxTagCount="responsive"
            placeholder="Viewing all merged trips"
        />

    const filtersBox =
        <div id="filtersbox" style={{ zIndex: 998, position: "absolute", top: 4, right: 4, width: 256, padding: 4 }}>
            <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                <div style={{ width: 453 }}>
                    <Row type="flex">
                        <Col span={24}>
                        <div id="filters">
                            <Col span={24} style={{marginBottom: 16}}>
                                {toggleAllEvents}
                            </Col>
                            <Col span={24}>
                                {selectTripsBox}
                            </Col>
                        </div>

                        </Col>
                    </Row>
                </div>

            </div>

        </div>


    return (
        <MapContainer
            id="map_container"
            center={position}
            zoom={15}
            zoomSnap={0.25}
            scrollWheelZoom={true}
            style={{ height: '100%', width: '100%', minHeight: 250 }}
        >
            {filtersBox}
            <LayersControl position="topleft">
                <BaseLayer checked name="Mapbox Satellite">
                    <TileLayer
                        attribution='&copy; <a https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                        url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia2FqYXZhLWp5cmkiLCJhIjoiY2x2cDFzNGRvMDJjbjJtcm8wdjF6YjNtciJ9.X4WsWxyul5MaNavSQE-PjA`}
                        maxZoom={22}
                    />
                </BaseLayer>
                <BaseLayer name="OpenStreetMap">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom={22}
                    />
                </BaseLayer>
            </LayersControl>
            <ImageOverlay id="map_sat" bounds={mapBounds} url='./images/port_map.jpg' opacity={showMap ? 0.5 : 0.0} />
            {polylineLocations.map((polyline, i) => (
                <Polyline
                    pathOptions={{ color: tripColors[polyline.trip_id] }}
                    positions={polyline.path}
                    key={"poly-" + i}
                />
            ))}
            {parkingSlots.map(poly =>
                <React.Fragment key={poly.id}>
                    {poly.polygon &&
                        <Polygon pathOptions={{
                            color: trip.discharge_yard_id === poly.id ? 'red'
                                : trip.pickup_yard_id === poly.id ? 'green'
                                    : 'orange'
                            , weight: 2
                        }} positions={poly.polygon}>
                            <Popup closeButton={false}>
                                <Text strong>Yard ID: </Text>{poly.id}<br />
                                <Text strong>Status: </Text>{poly.status}<br />
                                <Text strong>Trailer: </Text>{poly.trailer}<br />
                                <Text strong>Last Updated: </Text>{poly.updated}<br />
                            </Popup>
                        </Polygon>
                    }

                </React.Fragment>

            )}
            {tripEvents.map(event =>
                <Circle key={event.id} center={[event.payload?.position?.latitude, event.payload?.position?.longitude]} radius={1} color={event.message_type === "trailer/pickup" ? "#e6833c" : event.message_type === "deck" ? "#777" : event.message_type === "trailer/delivery" ? "#509423" : "#4a4948"}>
                    <Popup closeButton={false}>
                        <Text strong>Action: </Text>
                        {event.message_type === "trailer/pickup" && "PICKUP"}
                        {event.message_type === "deck" && "DECK"}
                        {event.message_type === "trailer/delivery" && "DELIVERY"}
                        <br />
                        <Text strong>Tractor: </Text>{event.tag}<br />
                        <Text strong>Event ID: </Text>{event.id || "---"}<br />

                        {event.payload?.yard_id && (
                            <>
                                <Text strong>Yard ID:</Text> {event.payload.yard_id}<br />
                            </>
                        )}
                        <Text strong>Time:</Text> {!!event.created ? moment.utc(event.created).format('DD/MM/YYYY HH:mm:ss') : event.created} <br />
                        <Text strong>Lat: </Text>{event.payload?.position?.latitude?.toFixed(6)} <Text strong>Long: </Text>{event.payload?.position?.longitude.toFixed(6)}
                    </Popup>
                </Circle>
                // <Circle key={event.trip_id + event.event_number} center={[event.discharge_latitude, event.discharge_longitude]} radius={3} color="#509423">
                //     <Popup>
                //         <Text strong>Discharge Time: </Text>{!!event.discharge_time && moment.utc(event.discharge_time).format('DD/MM/YYYY HH:mm:ss')} <br/>
                //         <Text strong>Lat: </Text>{event.discharge_latitude} <Text strong>Long: </Text>{event.discharge_longitude}
                //     </Popup>
                // </Circle>
            )}
        </MapContainer>
    )
}