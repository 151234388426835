import { useState, useEffect } from "react";

export const mergedTripLink = (trip) => {
    if (!trip.merged){
        return singleTripLink(trip.trip_id);
    }

    let queryString = "";
    const tripIds = trip.merged_trips; 
    tripIds.forEach(tripId => {
        queryString = queryString + "trips[]=" + tripId + "&"
    });
    // remove the last &
    queryString = queryString.slice(0, -1); 
    return <a href={"#/mergedtrip?" + queryString}>{tripIds.join(" + ")}</a>
}

export const singleTripLink = (tripId) => {
    return <a href={"#/singletrip/" + tripId}>{tripId}</a>
}
export const singleTripURL = (tripId) => {
    return "#/singletrip/" + tripId;
}

export function authHeader() {
    // Do not append jwt token when not using api auth
    if (!window.vtrack_config.USE_API_LOGIN) return null;
    const token = localStorage.getItem('token');
    if (token) {
        return 'Bearer ' + token ;
    } else {
        return null;
    }
}

export function formatMinutes(minutesInput) {
    // Calculate the total number of seconds from the given minutes input
    let totalSeconds = Math.floor(minutesInput * 60);

    // Calculate the number of minutes
    let minutes = Math.floor(totalSeconds / 60);

    // Calculate the remaining seconds
    let seconds = totalSeconds % 60;


    // Display the result in "minutes:seconds" format
    return `${minutes} minutes ${seconds} seconds`;
}

export function getTripDuration(trip) {
    if (!trip.trip_duration || trip.trip_duration == null || trip.trip_duration == undefined){
        let updated = new Date(trip.updated_message_time);
        let created = new Date(trip.created_message_time);
        let trip_duration = (updated - created) / 1000 / 60;
        return formatMinutes(trip_duration);
    }
    return formatMinutes(trip.trip_duration);

}

export const hexcolor_array = [
    "#FF0000", // Red
    "#00FF00", // Green
    "#0000FF", // Blue
    "#FFFF00", // Yellow
    "#FF00FF", // Magenta
    "#00FFFF", // Cyan
    "#800000", // Maroon
    "#808000", // Olive
    "#008000", // Dark Green
    "#800080", // Purple
    "#008080", // Teal
    "#000080", // Navy
    "#FFA500", // Orange
    "#A52A2A", // Brown
    "#8A2BE2", // Blue Violet
    "#D2691E", // Chocolate
    "#DC143C", // Crimson
    "#2E8B57", // Sea Green
    "#8B4513", // Saddle Brown
    "#6A5ACD", // Slate Blue
]

export default function NextcloudImage({ imageUrl, username, password }) {
    const [imageSrc, setImageSrc] = useState(null);
  
    useEffect(() => {
      // Create the Basic Authentication header
        const headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
        headers.set("User-Agent", "viztrack/1.0")
        headers.set("Access-Control-Allow-Origin", "*")
      
        // Fetch the image from Nextcloud
        fetch(imageUrl, { headers })
            .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
            })
            .then(blob => {
            // Create a URL from the Blob and set it as the image source
            const objectUrl = URL.createObjectURL(blob);
            setImageSrc(objectUrl);
            })
            .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            });
    
        // Cleanup the object URL when the component is unmounted
        return () => {
            if (imageSrc) {
            URL.revokeObjectURL(imageSrc);
            }
        };
        }, [imageUrl, username, password]);
  
        return (
        <div>
            {imageSrc ? (
            <img src={imageSrc} alt="Nextcloud file" />
            ) : (
            <p>Loading image...</p>
            )}
        </div>
        );
  }